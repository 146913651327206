import api from './api';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  
  edit: (params) => {
    return api.put(`/subscribers/${params.id}`, params);
  },

  get: (id) => {
    return api.get(`/subscribers/${id}`);
  },

};

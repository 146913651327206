import { useEffect, useState } from "react";
import BarChart from "components/charts/BarChart";
import Card from "components/card";
import ApiDashboard from "services/ApiDashboard";

const DailyTraffic = (props) => {
  const [dashboard, setDashboard] = useState([]);
  const [day, setDay] = useState(0);
  const [barChartOptionsDailyTraffic, setBarChartOptionsDailyTraffic] = useState({});
  const [barChartDataDailyTraffic, setBarChartDataDailyTraffic] = useState([]);

  useEffect(() => {
    setDay(props.sentToday);
  }, [props.sentToday]);
  
  
  useEffect(() => {
    const getDataDashboard = async () => {
      try {
        const response = await ApiDashboard.getSendedThisMonth();
        setDashboard(response.data.data);
      } catch (error) {
        console.error('Erro ao buscar integração --> ', error.response.data.message);
      }
      
    }

    getDataDashboard();
  }, []);

  useEffect(() => {
    const totais = dashboard.map(a => a.count);
    const days = dashboard.map(a => a.day); 

    setBarChartDataDailyTraffic([
      {
        name: "Daily Traffic",
        data: totais,
      },
    ]);
    
    setBarChartOptionsDailyTraffic({
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          backgroundColor: "#000000"
        },
        onDatasetHover: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
        theme: "dark",
      },
      xaxis: {
        categories: days,
        show: false,
        labels: {
          show: true,
          style: {
            colors: "#A3AED0",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        color: "black",
        labels: {
          show: true,
          style: {
            colors: "#CBD5E0",
            fontSize: "14px",
          },
        },
      },
      grid: {
        show: false,
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            [
              {
                offset: 0,
                color: "#4318FF",
                opacity: 1,
              },
              {
                offset: 100,
                color: "rgba(67, 24, 255, 1)",
                opacity: 0.28,
              },
            ],
          ],
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "40px",
        },
      },
    });
  }, [dashboard]);
  
  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <p className="text-sm font-medium leading-4 text-gray-600">
            Hoje
          </p>
          <p className="text-[34px] font-bold text-navy-700 dark:text-white">
            {day} &nbsp;
            <span className="text-sm font-medium leading-6 text-gray-600">
               msgs
            </span>
          </p>
        </div>
        {/* <div className="mt-2 flex items-start">
          <div className="flex items-center text-sm text-green-500">
            <MdArrowDropUp className="h-5 w-5" />
            <p className="font-bold"> +2.45% </p>
          </div>
        </div> */}
      </div>

      <div className="h-[300px] w-full pt-10 pb-0">
        <BarChart
          chartData={barChartDataDailyTraffic}
          chartOptions={barChartOptionsDailyTraffic}
        />
      </div>
    </Card>
  );
};

export default DailyTraffic;

import React, { useMemo, useEffect, useState } from 'react'
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect
} from "react-table";
import { useRowSelectColumn } from '@lineup-lite/hooks';
import { GrFormSearch } from 'react-icons/gr';
import CustomerModal from './CustomerModal';
import ApiCustomer from 'services/ApiCustomer';
import LoadingBar from 'react-top-loading-bar'


export function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  placeholder,
  }) {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  
  
  return (
    <span className='flex justify-between  pt-10 pb-10 '>
      <GrFormSearch fontSize={38} color='gray' className='absolute text-center text-gray-500 mt-3 ml-3 min-w-40' />
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        className='w-8/12 rounded-xl border p-4 text-gray-500 cursor-pointer'
        type="search"
        placeholder={placeholder}
      />
      
    </span>
  )
};

const Table = ({ placeholder  }) => {

  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [progress, setProgress] = useState(0)

  const openModal = () => {
    setIsModalOpen(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };


  async function getData(pageIndex, searchTerm) {
    // console.log('getData --> ', pageIndex);
    try {
      setProgress(10)
      const response = await ApiCustomer.list({
        page: pageIndex + 1,
        searchTerm: searchTerm
      });
      setProgress(50)
      // console.log('ApiCustomer --> ', response.data);
      const { data, meta } = response.data;
      setTotalRows(meta.total);
      setCurrentPage(pageIndex);
      setPageCount(meta.last_page);
      setData(data);
      setProgress(100)
      return { data, totalPages: meta.last_page, totalRows: meta.total };
    } catch (error) {
      console.error('Error fetching customer data:', error);
      return { data: [], totalPages: 0, totalRows: 0 };
    }
  }

  async function handleSearch() {
    // Substitua a URL abaixo pela URL da sua rota de pesquisa na API
    await getData(pageIndex, searchTerm);
    
  };

  
  const handleActionSuccess = async () => {
    await getData(0);
  };


  useEffect(() => {
    async function fetchData() {
      const { data, totalRows } = await getData(pageIndex, searchTerm);
      setData(data);
      console.log('Customers --> ', data);

      // Update the react-table pagination state with the total rows
      setGlobalFilter(totalRows);
      gotoPage(pageIndex);
    }

    fetchData();
  }, []);

  

  const columns = useMemo(() => [
    {
      Header: "Nome",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Celular",
      accessor: "phone",
    },
    {
      Header: "Tags",
      accessor: "tags",
    },
    {
      Header: "Opções",
      accessor: "status",
    },

  ], []);

  const { getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setPageSize,
  } =
    useTable({
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
      useGlobalFilter,
      usePagination,
      useRowSelect,
      useRowSelectColumn
    );
  const { pageIndex } = state;


  return (
    <div>
      <LoadingBar
        color='#22C55E'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <CustomerModal isOpen={isModalOpen} onRequestClose={closeModal} onActionSuccess={handleActionSuccess} />
      <div className='flex justify-between pt-10 pb-10'>
      <div className='w-full relative'>
  <div className="w-full flex-1">
    <div className="relative">
      <input
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className='w-full rounded-xl border p-4 pl-10 pr-10 text-gray-500 cursor-pointer'
        type="search"
        placeholder={placeholder}
      />
      {searchTerm && (
        <span
          className="absolute inset-y-0 left-2 flex items-center cursor-pointer"
          onClick={() => setSearchTerm('')}
        >
          <svg
            className="w-6 h-6 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      )}
      <span
        className="absolute inset-y-0 right-2 flex items-center cursor-pointer"
        onClick={handleSearch}
      >
        <svg
          className="w-6 h-6 text-gray-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-4.35-4.35M15 10a5 5 0 11-10 0 5 5 0 0110 0z"
          />
        </svg>
      </span>
    </div>
  </div>
</div>



  <div className='flex items-center'>
    <button
      className="bg-green-500 text-white rounded-xl p-4 border-1 cursor-pointer ml-2"
      onClick={openModal}
    >
      Cadastrar
    </button>

    <button
      className='bg-white rounded-xl p-4 border-1 cursor-pointer ml-2'
    >
      Export
    </button>
  </div>
</div>



      
      <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-10">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}
                          className="px-6 py-5 text-left text-20 font-medium text-gray-400 uppercase rounded-sm tracking-wider"
                        >
                          {column.render("Header")}
                          {column.id === 'selection' && column.render('Summary')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200">
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()} className="px-6 py-10 whitespace-nowrap">{cell.render("Cell")}</td>
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div className="pagination mt-4 flex items-center justify-center space-x-4">
        <button
          onClick={() => {
            gotoPage(0);
            getData(0);
          }}
          disabled={currentPage === 0}
          className={`px-4 py-2 rounded-md ${currentPage === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
        >
          &laquo; First
        </button>
        {Array.from({ length: pageCount }, (_, pageIndex) => (
          <button
            key={pageIndex}
            onClick={() => {
              gotoPage(pageIndex);
              // Call getData with the correct page number
              getData(pageIndex);
            }}
            className={`px-4 py-2 rounded-md ${currentPage === pageIndex ? 'bg-blue-600 text-white' : 'bg-gray-200 hover:bg-blue-500 hover:text-white'
              }`}
          >
            {pageIndex + 1}
          </button>
        ))}
        <button
          onClick={() => {
            gotoPage(pageCount - 1);
            getData(pageCount - 1); // Use pageCount - 1 para obter a última página
          }}
          disabled={currentPage === pageCount - 1}
          className={`px-4 py-2 rounded-md ${currentPage === pageCount - 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
        >
          Last &raquo;
        </button>
      </div>
      <p className="text-gray-500 text-sm mt-2 text-center">
        Total de Registros: {totalRows}
      </p>



    </div>
  )
}

export default Table
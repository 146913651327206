import Card from "components/card";
import Progress from "components/progress";
import ApiDashboard from "services/ApiDashboard";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const QtdNotifications = (props) => {
  const [dashboard, setDashboard] = useState({
    plan_total_messages: 0,
    plan_date_expiration: '',
    sended_this_month: []
  });
  const [sendedPercentual, setSendedPercentual] = useState(0);

  useEffect(() => {
    const getDataDashboard = async () => {
      try {
        const response = await ApiDashboard.getSendedNotifications();
        setDashboard(response.data);
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar os dados da Dashboard. Tente novamente em breve.')
        console.error('Erro ao buscar integração --> ', error.response.data.message);
      }
      
    }

    getDataDashboard();
  }, []);

  useEffect(() => {
    const sum = dashboard.sended_this_month.reduce((accumulator, value)=>{
      return accumulator + value.count;
    }, 0);

    const percentual = (sum / dashboard.plan_total_messages ) * 100;
    setSendedPercentual(~~percentual);
    
  }, [dashboard])

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div class="relative flex items-center justify-between pt-4">
        <div class="text-xl font-bold text-navy-700 dark:text-white">
          Notificações Enviadas
        </div>
        
      </div>

      <div class="mt-8 overflow-x-scroll xl:overflow-hidden">
      <table className="w-full">
  <thead>
    <tr>
      <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
        <p className="text-xs tracking-wide text-gray-600">Total de Notificações Enviadas</p>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
        <div className="flex items-center gap-2">
          <div className={`rounded-full w-full`}> {/* Barra de progresso 100% */}
            <Progress width="w-full" value={sendedPercentual} /> {/* Valor estático para o exemplo */}
          </div>
          <p className="text-sm font-bold text-navy-700 dark:text-white">
          {sendedPercentual}%
          </p>
        </div>
      </td>
    </tr>
    <tr>
      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          Contratado: {dashboard.plan_total_messages}
        </p>
      </td>
    </tr>
    <tr>
      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          Seu plano é válido até {dashboard.plan_date_expiration}
        </p>
      </td>
    </tr>
  </tbody>
</table>


      </div>
    </Card>
  );
};

export default QtdNotifications;

import api from './api';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: (params) => {
    //console.log('page ---', params.page, params.searchTerm);
    return api.get('/payments');
  },

  // criar um novo cliente
  create: (params) => {
    return api.post('/payments', params);
  },

};

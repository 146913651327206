import React, { useState, useEffect } from 'react';
import Card from "components/card";
import ApiSubscriber from "services/ApiSubscriber";
import ApiPayment from 'services/ApiPayment';
import ApiPlan from "services/ApiPlan";
import toast from 'react-hot-toast';
import { PayPalButton } from "react-paypal-button-v2";
import { BsCheckCircle } from 'react-icons/bs';
import { format, parseISO  } from 'date-fns';

const Profile = () => {
  const [selectedPlan, setSelectedPlan] = useState({});
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    email: '',
    timezone: '',
    password: '',
    plan_id: 1
  });
  const [expirationDate, setExpirationDate] = useState('');
  const [plans, setPlans] = useState([]);
  const [planoAtual, setPlanoAtual] = useState({
    description: ''
  });
  const [subscriptionActive, setSubscriptionActive] = useState(true);

  const paypalOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    intent: 'subscription',
    currency: 'BRL',
    vault: true,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeSelectedPlan = (e) => {
    const { name, value } = e.target;

    const plano = plans.filter(plan => plan.id == value);

    setSelectedPlan({
      plan_id: plano[0].id,
      paypal_plan_id: plano[0].paypal_plan_id
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      if(formData.password !== '' && formData.password.length < 8){
        toast.error('A senha deve ter no mínimo 8 caracteres.');
        return false;
      }

      const response = await ApiSubscriber.edit(formData);
      toast.success('Dados atualizados com sucesso.');
      
    } catch (error) {
      console.error(error);
      toast.error('Ocorreu um erro. Tente novamente.');
    }
  };

  const getSubscriber = async () => {
    const user = JSON.parse(localStorage.getItem('userData'));
    const { data:{ data } } = await ApiSubscriber.get(user.subscriber_id);

    setFormData({
      id: data.id,
      name: data.name,
      email: data.email,
      timezone: data.timezone,
      password:'',
      plan_id: data.plan_id
    });

    setExpirationDate(formatDateToBR(data.expiration_date));
    
  }

  const getPlans = async () => {
    const result = await ApiPlan.get();
    const planos = result.data;
    setPlans(result.data);

    const plano = planos.filter(plan => plan.id == formData.plan_id);
    setPlanoAtual(plano[0]);
  }

  useEffect(() => {
    getSubscriber();
    getPlans();

    setSubscriptionActive(localStorage.getItem('active') == 'true' ? true : false);

  }, []);

  function formatDateToBR(date) {
    const [ano, mes, dia] = date.split('-');
    return `${dia}/${mes}/${ano}`;
  }

  const isCurrentUserPlan = (planId) => {
    return formData.plan_id === planId;
  }
  
  return (
    <div className="mt-5 grid grid-cols-1 md:grid-cols-1 gap-4">
      {!subscriptionActive &&
      <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
        <p class="font-bold">Sua assinatura está vencida</p>
        <p>Seus clientes não estão recebendo as notificações por WhatsApp. Renove agora mesmo a sua assinatura abaixo para continuar a enviar as notificações.</p>
      </div>}
      {subscriptionActive &&
      <div class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
        <p class="font-bold">Sua assinatura está ativa!</p>
        <p>As notificações serão enviadas normalmente.</p>
      </div>}
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <form onSubmit={handleSubmit} className="grid gap-4 w-full">
            <div className="md:col-span-1 col-span-1">
              <label htmlFor="nome" className="block text-gray-600">Nome</label>
              <input
                type="text"
                id="nome"
                name="nome"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-400"
              />
            </div>
            <div className="md:col-span-1 col-span-1">
              <label htmlFor="email" className="block text-gray-600">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-400"
              />
            </div>
            <div className="md:col-span-1 col-span-1">
              <label htmlFor="senha" className="block text-gray-600">Timezone</label>
              <input
                type="text"
                id="timezone"
                name="timezone"
                value={formData.timezone}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-400"
              />
            </div>
            <div className="md:col-span-1 col-span-1">
              <label htmlFor="senha" className="block text-gray-600">Senha</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-400"
                autoComplete='off'
              />
            </div>
            <div className="md:col-span-1 col-span-1">
              <label htmlFor="senha" className="block text-gray-600">Vencimento</label>
              <input
                type="text"
                id="expiration_date"
                name="expiration_date"
                value={expirationDate}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-400"
                autoComplete='off'
                readOnly
              />
            </div>
            <div className="md:col-span-1 col-span-1">
              <label htmlFor="plano_atual" className="block text-gray-600">Plano Atual</label>
              <input
                type="text"
                value={planoAtual.description}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-400"
                autoComplete='off'
                readOnly
              />
            </div>
            <div className="md:col-span-3 col-span-1 flex justify-end">
              <button type="submit" className="w-full bg-green-500 text-white px-2 py-1 rounded-md hover:bg-green-600 md:w-1/5">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </Card>
      <h1 className='text-4xl font-bold'>Planos</h1>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
        {plans.map(({ id, description, price, num_limit_messages, paypal_plan_id }, index) => {
          const isCurrentPlan = isCurrentUserPlan(id);
          const isRecommendedPlan = id === 2 && !isCurrentPlan; 
          const isRegularPlan = !isCurrentPlan && !isRecommendedPlan;
          return (
            <Card extra={`w-full h-full p-4 shadow-xl${isCurrentPlan ? ' bg-green-500' : isRecommendedPlan ? ' bg-purple-500' : ''}`}>
            {isCurrentPlan && (
              <label className="text-green-500 py-1 text-center font-bold">Seu plano atual</label>
            )}
            {isRecommendedPlan && (
              <label className="text-purple-500 py-1 text-center font-bold">Recomendado</label>
            )}
            <div className={`md:col-span-1 col-span-1 text-center${isRegularPlan ? ' mt-5' : ''}`}>
              <div className="flex items-center justify-center">
                {isCurrentPlan && (
                  <span className="text-2xl text-green-500 mr-2">
                    <BsCheckCircle /> {/* Ícone de "checked" */}
                  </span>
                )}
                <span className="font-bold text-2xl">{description}</span>
              </div>
              <div className="text-xl">{num_limit_messages} mensagens</div>
              <div className="text-2xl pb-5">R$ {price}</div>
              <div className='content-center'>
                <button
                  className="bg-green-500 text-white py-2 px-4 rounded-md"
                  onClick={() => handleChangeSelectedPlan(id)}
                >
                  Escolher este plano
                </button>
              </div>
            </div>
          </Card>
            )
        })}
      </div>
      { selectedPlan.plan_id &&
      <div className="mt-5 grid grid-cols-1 md:grid-cols-1 gap-4">
        <Card extra={"w-full h-full p-4"}>
          <PayPalButton
            options={paypalOptions}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                plan_id: selectedPlan.paypal_plan_id
              });
            }}
            onApprove={(data, actions) => {
              // Capture the funds from the transaction
              return actions.subscription.get().then(async function(details) {
                const user = JSON.parse(localStorage.getItem('userData'));
                // Show a success message to your buyer
                toast.success('Sua assinatura foi realizada com sucesso!');

                const paymentData = {
                  orderId: data.orderID,
                  subscriptionId: data.subscriptionID,
                  payload: JSON.stringify(details),
                  subscriber_id: user.subscriber_id,
                  plan_id: selectedPlan.plan_id,
                };
                const payment = await ApiPayment.create(paymentData);
                await getSubscriber();
                await getPlans();

                return payment;

              });
            }}
          />
        </Card>
      </div>
      }
    </div>
  );
};

export default Profile;

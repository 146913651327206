import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
} from "react-table";
import { useRowSelectColumn } from "@lineup-lite/hooks";
import { GrFormSearch } from "react-icons/gr";
import ApiCall from "services/ApiCall";

export function GlobalFilter({ globalFilter, setGlobalFilter, placeholder }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span className="flex justify-between  pb-10 pt-10 ">
      <GrFormSearch
        fontSize={38}
        color="gray"
        className="min-w-40 absolute ml-3 mt-3 text-center text-gray-500"
      />
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        className="w-8/12 cursor-pointer rounded-xl border p-4 text-gray-500"
        type="search"
        placeholder={placeholder}
      />
    </span>
  );
}

const Table = ({ placeholder }) => {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  async function getData(pageIndex, searchTerm) {
    try {
      const response = await ApiCall.list({
        page: pageIndex + 1,
        searchTerm: searchTerm,
      });
      const { data, meta } = response.data;
      setTotalRows(meta.total);
      setCurrentPage(pageIndex);
      setPageCount(meta.last_page);
      setData(data);
      return { data, totalPages: meta.last_page, totalRows: meta.total };
    } catch (error) {
      console.error("Error fetching customer data:", error);
      return { data: [], totalPages: 0, totalRows: 0 };
    }
  }

  async function handleSearch() {
    // Substitua a URL abaixo pela URL da sua rota de pesquisa na API
    await getData(pageIndex, searchTerm);
  }

  const handleActionSuccess = async () => {
    await getData(0);
  };

  useEffect(() => {
    async function fetchData() {
      const { data, totalRows } = await getData(pageIndex, searchTerm);
      setData(data);

      // Update the react-table pagination state with the total rows
      setGlobalFilter(totalRows);
      gotoPage(pageIndex);
    }

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Criado",
        accessor: "created_at",
      },
      {
        Header: "Order At",
        accessor: "ordered_at",
      },
      {
        Header: "Type",
        accessor: "event_type.description",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Order Number",
        accessor: "order_number",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useRowSelectColumn
  );
  const { pageIndex } = state;

  return (
    <div>
      <div className="flex justify-between pb-10 pt-10">

        {/* <div className="flex items-center">
          <button className="border-1 ml-2 cursor-pointer rounded-xl bg-white p-4">
            Export
          </button>
        </div> */}
      </div>

      <div className="mt-2 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-10">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          className="text-20 rounded-sm px-6 py-5 text-left font-medium uppercase tracking-wider text-gray-400"
                        >
                          {column.render("Header")}
                          {column.id === "selection" &&
                            column.render("Summary")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap px-6 py-10"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="pagination mt-4 flex items-center justify-center space-x-4">
        <button
          onClick={() => {
            gotoPage(0);
            getData(0);
          }}
          disabled={currentPage === 0}
          className={`rounded-md px-4 py-2 ${
            currentPage === 0
              ? "cursor-not-allowed bg-gray-300"
              : "bg-blue-500 hover:bg-blue-600"
          } text-white`}
        >
          &laquo; First
        </button>
        {Array.from({ length: pageCount }, (_, pageIndex) => (
          <button
            key={pageIndex}
            onClick={() => {
              gotoPage(pageIndex);
              // Call getData with the correct page number
              getData(pageIndex);
            }}
            className={`rounded-md px-4 py-2 ${
              currentPage === pageIndex
                ? "bg-blue-600 text-white"
                : "bg-gray-200 hover:bg-blue-500 hover:text-white"
            }`}
          >
            {pageIndex + 1}
          </button>
        ))}
        <button
          onClick={() => {
            gotoPage(pageCount - 1);
            getData(pageCount - 1); // Use pageCount - 1 para obter a última página
          }}
          disabled={currentPage === pageCount - 1}
          className={`rounded-md px-4 py-2 ${
            currentPage === pageCount - 1
              ? "cursor-not-allowed bg-gray-300"
              : "bg-blue-500 hover:bg-blue-600"
          } text-white`}
        >
          Last &raquo;
        </button>
      </div>
      <p className="mt-2 text-center text-sm text-gray-500">
        Total de Registros: {totalRows}
      </p>
    </div>
  );
};

export default Table;

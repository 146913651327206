import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";
import api from "services/api";

const root = ReactDOM.createRoot(document.getElementById("root"));

const token = localStorage.getItem('token');
if(token){
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

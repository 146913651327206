import api from './api';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: () => {
    return api.get('/send-events');
  },

  types: () => {
    return api.get('/event-types');
  },

  create: (params) => {
    return api.post('/send-events', params);
  },

  update: (params) => {
    return api.put(`/send-events/${params.id}`, params);
  },

  delete: (id) => {
    return api.delete(`/send-events/${id}`);
  }

};

import toast from 'react-hot-toast';

export const notify = async (message, type) => {
    const toastOptions = {
      className: '',
      duration: 5000,
    };
  
    if (type === 'success') {
      toastOptions.style = {
        background: '#00c91c',
        color: '#fff',
      };
    } else if (type === 'error') {
      toastOptions.style = {
        background: 'red',
        color: '#fff',
      };
    } else if (type === 'warning') {
        toastOptions.style = {
          background: 'orange',
          color: '#fff',
        };
      }
  
    await toast(message, toastOptions);
};
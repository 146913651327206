import api from './api';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {

  get: () => {
    return api.get(`/dashboard`);
  },
  getSendedNotifications: () => {
    return api.get(`/dashboard/sended-notifications`);
  },
  getHistoryNotifications: () => {
    return api.get(`/dashboard/history-notifications`);
  },
  getCallsByType: () => {
    return api.get(`/dashboard/calls-type`);
  },
  getSendedThisMonth: () => {
    return api.get(`/dashboard/sended-this-month`);
  },
};

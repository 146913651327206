import Card from "components/card";
import { useState, useEffect } from "react";
import ApiSubscriber from "services/ApiSubscriber";

const Integrations = () => {
  const [subscriber, setSubscriber] = useState({});
  const demoWebhookSendWhatsapp = {
    "phone":"5581997479874",
    "message":"Test Message",
    "api_key":"your-api-key"
  };

  useEffect(() => {
    const getSubscriber = async () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const results = await ApiSubscriber.get(userData.subscriber_id);
      if (results?.data) {
        setSubscriber(results.data.data);
      }
    };

    getSubscriber();
  }, []);

  return (
    <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Webhook
          </div>
        </div>

        <div className="mt-4 h-full">API KEY: {subscriber?.apikey}</div>

        <div className="mt-4 h-full">
          Você poderá integrar outros sistemas ao CommunicaZap através de nossos
          webhooks.
        </div>

        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Enviar mensagens de WhatsApp:
          </div>
        </div>

        <span>URL: https://api.communicazap.com/webhook/send-whatsapp</span>
        <span>Method: POST</span>

        <code class="inline-flex items-center space-x-4 rounded-lg bg-gray-800 p-4 pl-6 text-left text-sm text-white sm:text-base">
          <span class="flex gap-4">
            <span class="flex-1">
              <pre>{JSON.stringify(demoWebhookSendWhatsapp, null, 2)}</pre>
            </span>
          </span>
        </code>
      </Card>
    </div>
  );
};

export default Integrations;


import Table from './Table'
const Logs = () => {
  return (
    <div>
      

      <div className="mt-5 grid h-full grid-cols-1 gap-5">
      <Table/>

       
      </div>
    </div>
  );
};

export default Logs;

import Card from "components/card";
import { useState, useEffect } from "react";
import ApiEvents from "services/ApiEvents";
import { notify } from "utils/notify";

const Messages = () => {
  const [isOnClientNew, setIsOnClientNew] = useState(false);
  const [isOnOrderNew, setIsOnOrderNew] = useState(false);
  const [isOnOrderPaid, setIsOnOrderPaid] = useState(false);
  const [isOnOrderCancelled, setIsOnOrderCancelled] = useState(false);
  const [isOnCartAbandoned, setIsOnCartAbandoned] = useState(false);
  const [messageClientNew, setMessageClientNew] = useState('🎉 Bem-vindo(a) ao [store.name]! 🎉 Descubra produtos incríveis e aproveite ofertas exclusivas. Estamos aqui para tornar sua experiência de compra especial. 😊');
  const [messageOrderNew, setMessageOrderNew] = useState('🎉 Bem-vindo(a) ao [store.name]!\n\n🎉 Descubra produtos incríveis e aproveite ofertas exclusivas. Estamos aqui para tornar sua experiência de compra especial. 😊');
  const [messageOrderPaid, setMessageOrderPaid] = useState('🎉🛍️ Olá [customer.name]! \n\n🛍️🎉 Estamos radiantes em informar que seu pagamento para o pedido #[order.number] foi aprovado com sucesso! \n🥳✅ Agradecemos muito por escolher a nossa loja para suas compras. Agora, nossa equipe está ocupada preparando seus produtos com todo o cuidado. \n📦👏 Assim que seu pedido for despachado, você receberá um número de rastreamento para acompanhar a entrega. \n🚚📮 Se tiver alguma dúvida ou precisar de assistência adicional, não hesite em nos contatar. Estamos aqui para ajudar! \n📞💬 Agradecemos sua confiança em nós e esperamos que você aproveite suas compras. Tenha um ótimo dia! 😊🛒');
  const [messageOrderCancelled, setMessageOrderCancelled] = useState('👋 Olá [customer.name]!\n\nNotamos que o seu pedido foi cancelado e gostaríamos de entender melhor o motivo para podermos melhorar nossos serviços. \n😊 Ficaríamos gratos se você pudesse compartilhar conosco o que motivou o cancelamento. Sua opinião é muito importante para nós e queremos garantir que sua experiência seja a melhor possível.\nPor favor, sinta-se à vontade para nos contar o que aconteceu e como podemos resolver isso da melhor forma para você. Estamos aqui para ajudar! \n🛒✨ Esperamos poder servi-lo novamente em breve. Obrigado por escolher [store.name]! 💙');
  const [messageCartAbandoned, setMessageCartAbandoned] = useState('🛒 Olá [customer.name]!\n\n🛍️ Parece que você esqueceu algo no seu carrinho de compras na nossa loja. Não queremos que perca a oportunidade de adquirir esses produtos incríveis que escolheu!\n👉 Clique no link abaixo e retorne ao seu carrinho para concluir sua compra:\n\n[cart.link]\n\n💬 Se tiver alguma dúvida ou precisar de assistência, nossa equipe de suporte está pronta para ajudar.\nAgradecemos por escolher a nossa loja. Esperamos que tenha uma ótima experiência de compra!\n\nAtenciosamente,\n\n[store.name] 🌟');
  const [sendEvents, setSendEvents] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);

  const salvarEventos = async () => {
    const send_events = [
      {
        event_type_id:  1,
        description:    '',
        message:        messageClientNew,
        active:         isOnClientNew
      },
      {
        event_type_id:  2,
        description:    '',
        message:        messageOrderNew,
        active:         isOnOrderNew
      },
      {
        event_type_id:  3,
        description:    '',
        message:        messageOrderPaid,
        active:         isOnOrderPaid
      },
      {
        event_type_id:  4,
        description:    '',
        message:        messageOrderCancelled,
        active:         isOnOrderCancelled
      },
      {
        event_type_id:  5,
        description:    '',
        message:        messageCartAbandoned,
        active:         isOnCartAbandoned
      },
    ];

    try {
      send_events.forEach(async event => {
        await ApiEvents.create(event);
      });
      notify('Mensagens salvas com sucesso.', 'success');
    } catch (error) {
      console.error(error);
      notify('Ocorreu um erro.', 'error');
    }
  }

  useEffect(() => {
    const getEvents = async () => {
      const results = await ApiEvents.list();
      if(results?.data){
        setSendEvents(results.data.data);
      }
    }

    const getEventTypes = async () => {
      const results = await ApiEvents.types();
      if(results?.data){
        setEventTypes(results.data);
      }
    }

    getEvents();
    getEventTypes();
  
  }, []);

  useEffect(() => {
    sendEvents.forEach(event => {
      switch (event.event_type_id) {
        case 1:
          setIsOnClientNew(event.active === 1? true : false);
          setMessageClientNew(event.message);
          break;
        case 2:
          setIsOnOrderNew(event.active === 1? true : false);
          setMessageOrderNew(event.message);
          break;
        case 3:
          setIsOnOrderPaid(event.active === 1? true : false);
          setMessageOrderPaid(event.message);
          break;
        case 4:
          setIsOnOrderCancelled(event.active === 1? true : false);
          setMessageOrderCancelled(event.message);
          break;
        case 5:
          setIsOnCartAbandoned(event.active === 1? true : false);
          setMessageCartAbandoned(event.message);
          break;
        default:
          break;
      }
    });
  
  }, [sendEvents])
  
  
  return (
    <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Novo Cliente
          </div>
          <div className="flex items-center space-x-2">
            <div class="relative mr-2 inline-block w-10 select-none align-middle transition duration-200 ease-in">
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isOnClientNew}
                    value="1"
                    onChange={()=>setIsOnClientNew(!isOnClientNew)}
                  />
                  <span className="slider round"></span>
                </label>
                {/* <p>{isOn ? 'ON' : 'OFF'}</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 h-full">
          <textarea
            className="h-40 w-full resize-none rounded-md border p-2"
            value={messageClientNew}
            onChange={event => {
              setMessageClientNew(event.target.value);
            }}
          ></textarea>
        </div>
      </Card>
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Novo Pedido
          </div>
          <div className="flex items-center space-x-2">
            <div class="relative mr-2 inline-block w-10 select-none align-middle transition duration-200 ease-in">
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isOnOrderNew}
                    value="1"
                    onChange={()=>setIsOnOrderNew(!isOnOrderNew)}
                  />
                  <span className="slider round"></span>
                </label>
                {/* <p>{isOn ? 'ON' : 'OFF'}</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 h-full">
          <textarea
            className="h-40 w-full resize-none rounded-md border p-2"
            value={messageOrderNew}
            onChange={event => {
              setMessageOrderNew(event.target.value);
            }}
            placeholder=""
          ></textarea>
        </div>
      </Card>

      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Pedido Pago
          </div>
          <div className="flex items-center space-x-2">
            <div class="relative mr-2 inline-block w-10 select-none align-middle transition duration-200 ease-in">
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isOnOrderPaid}
                    value="1"
                    onChange={()=>setIsOnOrderPaid(!isOnOrderPaid)}
                  />
                  <span className="slider round"></span>
                </label>
                {/* <p>{isOn ? 'ON' : 'OFF'}</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 h-full">
          <textarea
            className="h-40 w-full resize-none rounded-md border p-2"
            value={messageOrderPaid}
            onChange={event => {
              setMessageOrderPaid(event.target.value);
            }}
          ></textarea>
        </div>
      </Card>

      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Pedido Cancelado
          </div>
          <div className="flex items-center space-x-2">
            <div class="relative mr-2 inline-block w-10 select-none align-middle transition duration-200 ease-in">
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isOnOrderCancelled}
                    value="1"
                    onChange={()=>setIsOnOrderCancelled(!isOnOrderCancelled)}
                  />
                  <span className="slider round"></span>
                </label>
                {/* <p>{isOn ? 'ON' : 'OFF'}</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 h-full">
          <textarea
            className="h-40 w-full resize-none rounded-md border p-2"
            value={messageOrderCancelled}
            onChange={event => {
              setMessageOrderCancelled(event.target.value);
            }}
          ></textarea>
        </div>
      </Card>

      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Carrinho Abandonado
          </div>
          <div className="flex items-center space-x-2">
            <div class="relative mr-2 inline-block w-10 select-none align-middle transition duration-200 ease-in">
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isOnCartAbandoned}
                    value="1"
                    onChange={()=>setIsOnCartAbandoned(!isOnCartAbandoned)}
                  />
                  <span className="slider round"></span>
                </label>
                {/* <p>{isOn ? 'ON' : 'OFF'}</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 h-full">
          <textarea
            className="h-40 w-full resize-none rounded-md border p-2"
            value={messageCartAbandoned}
            onChange={event => {
              setMessageCartAbandoned(event.target.value);
            }}
          ></textarea>
        </div>
      </Card>

      <Card extra={"w-full h-full p-4"}>
        <button onClick={salvarEventos} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          Salvar Mensagens
        </button>
      </Card>

    </div>
  );
};

export default Messages;

import React from "react";
import {
  MdBarChart,
} from "react-icons/md";
import Card from "components/card";

import LineChart from "components/charts/LineChart";
import ApiDashboard from "services/ApiDashboard";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const TotalSpent = () => {
  const [dashboard, setDashboard] = useState({
    sended_last_months: []
  });
  const [lineChartOptionsTotalSpent, setLineChartOptionsTotalSpent] = useState({});
  const [lineChartDataTotalSpent, setLineChartDataTotalSpent] = useState([]);

  useEffect(() => {
    const getDataDashboard = async () => {
      try {
        const response = await ApiDashboard.getHistoryNotifications();
        setDashboard(response.data);
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar os dados da Dashboard. Tente novamente em breve.')
        console.error('Erro ao buscar integração --> ', error.response.data.message);
      }
      
    }

    getDataDashboard();
  }, []);

  useEffect(() => {
    const monthLabels = ['','JAN','FEV','MAR','ABR','MAI','JUN','JUL','AGO','SET','OUT','NOV','DEZ']
    const totais = dashboard.sended_last_months.map(a => a.count);
    const months = dashboard.sended_last_months.map(a => monthLabels[a.month]);

    setLineChartDataTotalSpent([
      {
        name: "Enviado",
        data: totais,
        // color green
        color: "#00c91c",
        
      },
    ]);

    setLineChartOptionsTotalSpent({
      legend: {
        show: false,
      },
      
      theme: {
        mode: "light",
      },
      chart: {
        type: "line",
    
        toolbar: {
          show: false,
        },
        
      },
    
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      style: {
        colors: ['#000000', '#000000', '#9C27B0']
      },
    
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          backgroundColor: "#000000"
        },
        theme: 'dark',
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      grid: {
        show: true,
      },
      xaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#A3AED0",
            fontSize: "12px",
            fontWeight: "500",
          },
        },
        type: "text",
        range: undefined,
        categories: months,
      },
    
      yaxis: {
        show: false,
      },
    });
  }, [dashboard])
  

  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <div class="text-xl font-bold text-navy-700 dark:text-white">
          Histórico de Notificações
        </div>
        
      
        <button className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
          
        </button>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <span className="text-sm font-medium text-green-600">Enviado</span>
        </div>
        <div className="h-full w-full">
          <LineChart
            options={lineChartOptionsTotalSpent}
            series={lineChartDataTotalSpent}
          />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;

import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  timeout: 10000,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      api.defaults.headers.common['Authorization'] = '';
      window.location.href = '/auth/sign-in';
    } else {
      return Promise.reject(error);
    }
  });

export default api;


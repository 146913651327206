
import Table from './Table'
const Customers = () => {
  return (
    <div>
      

      <div className="mt-5 grid h-full grid-cols-1 gap-5">
      <Table placeholder="        Search for customer's name"/>

       
      </div>
    </div>
  );
};

export default Customers;

import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import Account from "views/admin/account";
import Customers from "views/admin/customers";
import Logs from "views/admin/logs";
import Webhooks from "views/admin/webhooks";
import DataTables from "views/admin/tables";
import Connect from "views/admin/connect";
import Messages from "views/admin/messages";
import Integrations from "views/admin/integrations";
import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";
import SignOut from "views/auth/SignOut";

// Icon Imports
import {
  MdHome,
  MdDisplaySettings,
  MdQrCode2,
  MdMessage,
  MdBarChart,
  MdPerson,
  MdLock,
  MdFactCheck,
  MdLockOpen,
  MdWebhook,
  MdCable,
} from "react-icons/md";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Clientes",
    layout: "/admin",
    path: "customers",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Customers />,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  {
    name: "Conectar WhatsApp",
    layout: "/admin",
    icon: <MdQrCode2 className="h-6 w-6" />,
    path: "connect",
    component: <Connect />,
  },
  {
    name: "Mensagens",
    layout: "/admin",
    icon: <MdMessage className="h-6 w-6" />,
    path: "messages",
    component: <Messages />,
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Logs Mensagens",
    layout: "/admin",
    path: "logs",
    icon: <MdFactCheck className="h-6 w-6" />,
    component: <Logs />,
  },
  {
    name: "Webhooks",
    layout: "/admin",
    path: "webhooks",
    icon: <MdWebhook className="h-6 w-6" />,
    component: <Webhooks />,
  },
  {
    name: "Minha Conta",
    layout: "/admin",
    path: "account",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Account />,
  },
  {
    name: "Integrações",
    layout: "/admin",
    path: "integrations",
    icon: <MdCable className="h-6 w-6" />,
    component: <Integrations />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Sign Out",
    layout: "/auth",
    path: "sign-out",
    icon: <MdLockOpen className="h-6 w-6" />,
    component: <SignOut />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;

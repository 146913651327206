import api from './api';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  list: (params) => {
    //console.log('page ---', params.page, params.searchTerm);
    return api.get('/calls?page=' + params.page + '&search=' + params.searchTerm);
  },

  // criar um novo cliente
  create: (params) => {
    return api.post('/calls', params);
  },

  search: (params) => {
    return api.get('/calls/search', params);
  }

 

};

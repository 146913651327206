import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoIosWifi } from "react-icons/io";
import { IoWarning, IoSend, IoPeople } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Card from "components/card";


import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import QtdNotifications from "views/admin/default/components/QtdNotifications";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import VerifyToken from "../../auth/VerifyToken";
import { useEffect, useState } from "react";
import ApiDashboard from "services/ApiDashboard";
import toast from "react-hot-toast";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState({
    total_customers: 0,
    sended_today: 0,
    integration_connected: true,
  });

  useEffect(() => {
    const getDataDashboard = async () => {
      try {
        const response = await ApiDashboard.get();
        setDashboard(response.data);
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar os dados da Dashboard. Tente novamente em breve.')
        console.error('Erro ao buscar integração --> ', error.response.data.message);
      }
      
    }

    getDataDashboard();
  }, []);
  

  return (
    <div>
     
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<IoSend className="h-7 w-7" />}
          title={"Enviadas Hoje"}
          subtitle={dashboard.sended_today}
        />
        <Widget
          icon={<IoPeople className="h-6 w-6" />}
          title={"Customers"}
          subtitle={dashboard.total_customers}
        />
        {/* <Widget
          icon={<IoIosWifi className="h-7 w-7" />}
          title={"Status"}
          subtitle={"Conectado"}
        /> */}

<Card extra="!flex-row flex-grow items-center rounded-[20px]">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
          <IoIosWifi className="h-7 w-7" />
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">Status</p>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {/* label color green "Conectado" */}
          <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">
            {dashboard.integration_connected ? 'Conectado' : 'Desconectado'}
          </span>
        </h4>
      </div>
    </Card>
        
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        {/* <WeeklyRevenue /> */}
        <QtdNotifications
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2">
        

      <DailyTraffic sentToday={dashboard.sended_today} />

        <div className="grid grid-cols-1 gap-5 rounded-[20px]">
          
          <PieChartCard />
        </div>

        

        
      </div>
    </div>
  );
};

export default Dashboard;

import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import ApiWhastapp from "services/ApiWhastapp";
import { useState } from "react";
import QRCode from 'qrcode'
import { useEffect } from "react";
import { notify } from "utils/notify";

export default function Connect () {
  const [qrcode, setQrCode] = useState('');
  const [showQrCode, setShowQrCode] = useState(false);
  const [whatsappConnected, setWhatsappConnected] = useState(false);
  const [integrationWhatsApp, setIntegrationWhatsApp] = useState({});

  configureAbly({ key: process.env.REACT_APP_ABLY_API_KEY});
  const userData = JSON.parse(localStorage.getItem("userData"));
  const subscriber_id = userData.subscriber_id;
  
  useChannel(`public:whatsapp-connection-status-${subscriber_id}`, async (message) => {
    const integration = message.data.integration;
    setIntegrationWhatsApp(integration);

    //se houve alteracao de status e a linha está conectada
    if(integration.active !== integrationWhatsApp.active && integration.active === 1){
      notify('Linha conectada com sucesso!', 'success');
    }

    //se houve alteracao de status e a linha está desconectada
    if(integration.active !== integrationWhatsApp.active && integration.active === 0){
      notify('Linha desconectada', 'error');
    }
  });

  useChannel(`public:whatsapp-qrcode-${subscriber_id}`, async (message) => {
    const integration = message.data.integration;
    const qrCodeImage = await QRCode.toDataURL(integration.qrcode);
    setQrCode(qrCodeImage);
    setShowQrCode(true);
  });

  const generateQrCode = async () => {
    let response = await ApiWhastapp.qrcode();
    if (response.data.qrCode === null) {
      await ApiWhastapp.disconnect();
    }
    response = await ApiWhastapp.qrcode();
    const qrCodeImage = await QRCode.toDataURL(response.data.qrCode);
    setQrCode(qrCodeImage);
    setShowQrCode(true);
  }

  const disconnect = async () => {
    try {
      await ApiWhastapp.disconnect();
      notify('Desconectado com Sucesso!', 'success');
    } catch (error) {
      notify('Ocorreu um erro, tente novamente.', 'error');
      console.error('Erro ao desconectar integração --> ', error.response.data.message);
    }
  }

  useEffect(() => {
    const checkConnection = async () => {
      try {
        const response = await ApiWhastapp.getIntegration();
        setIntegrationWhatsApp(response?.data?.data[0]);
      } catch (error) {
        console.error('Erro ao buscar integração --> ', error.response.data.message);
      }
      
    }

    checkConnection();
  
  }, []);

  useEffect(() => {
    const active = integrationWhatsApp?.active === 1 ? true : false;
    setQrCode('');
    setShowQrCode(false);
    setWhatsappConnected(active);
  
  
  }, [integrationWhatsApp])
  
  

  return (
    <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
      {!whatsappConnected &&
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Conectar WhatsApp
          </div>
        </div>

        <div className="h-full flex flex-col items-center justify-center">
          {showQrCode && 
          <img
            src={qrcode}
            alt="QR Code"
            className="w-48 h-48 mx-auto mb-4"
          />
          }
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Para conectar sua linha do WhatsApp, escaneie o QR Code acima com o aplicatido do WhatsApp de seu celular.
          </p>
          <button onClick={generateQrCode} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
            Gerar QR Code
          </button>
        </div>
      </Card>
      }
      {whatsappConnected &&
      <Card>
        <div className="h-full flex flex-col items-center justify-center">
          <button onClick={disconnect} className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
            Desconectar WhatsApp
          </button>
        </div>
      </Card>
      }

      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Status de conexão
          </div>
          {/* <CardMenu /> */}
        </div>

        <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
          <div className="flex items-center mt-2">
            {!whatsappConnected &&
            <div className="bg-red-500 text-white px-2 py-1 rounded-md">
              Desconectado
            </div>
            }
            {whatsappConnected &&
            <div className="bg-green-500 text-white px-2 py-1 rounded-md">
              Conectado
            </div>
            }
          </div>
          <p className="mt-2">
            Seu WhatsApp está {whatsappConnected ? 'conectado' : 'desconectado'}.
          </p>
        </div>
      </Card>

    </div>

  );
};

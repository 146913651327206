import api from './api';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  qrcode: () => {
    return api.get('/integrations/qrcode');
  },

  connect: () => {
    return api.post('/integrations/connect');
  },

  disconnect: () => {
    return api.post('/integrations/disconnect');
  },

  getIntegration: () => {
    return api.get('/integrations');
  },

};

import {React, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

export default function SignOut() {
  // criar use Effect para deslogar o usuario
  const navigate = useNavigate();
  
  useEffect(() => {
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('active');
    navigate('/auth/sign-in');
  }, []);

  return (
    <div className="mt-8 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      
    </div>
  );
}

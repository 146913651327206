import {React, useEffect, useState} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import ApiAuth from "../../services/ApiAuth";
import { notify } from "utils/notify";


export default function SignIn() {
  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [auth, setAuth] = useState({
    email: "",
    password: "",
    device_name: "web",
  });

  async function login(e) {
    e.preventDefault();
    try {
        const { email, password, device_name } = auth;
        const response = await ApiAuth.login({ email, password, device_name });
        
        if (response && response.data) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("active", response.data.active);
          
          const userData = await ApiAuth.me(response.data.token);
          localStorage.setItem("userData", JSON.stringify(userData.data));
          
          const redirect_page = response.data.active ? '/admin/connect' : '/admin/account';
          
          navigate(redirect_page);
        } else {
          notify('Login ou senha inválidos, tente novamente. ', 'error'); 
        }

      } catch (error) {
        
        if (error.response.data.errors) {
          console.error('Erro na API: ', error.response.message);
          const errorMessage = error.response.data.message || 'Erro desconhecido';
          notify(errorMessage, 'error');
        } else {
          console.error(error.message);
          notify('Erro de rede', 'error');
        }
      }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setAuth({ ...auth, [name]: value });
  }

  const loginWithToken = async (token) => {
    try {
      const response = await ApiAuth.loginWithToken({ token });
      
      if (response && response.data) {
        notify('Dados validados com sucesso', 'success');
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("active", response.data.active);
        
        const userData = await ApiAuth.me(response.data.token);
        localStorage.setItem("userData", JSON.stringify(userData.data));
        
        const redirect_page = response.data.active ? '/admin/connect' : '/admin/account';
        
        navigate(redirect_page);
      } else {
        console.error('Token invalido!');
        notify('O token informado é inválido. Verifique seu email e entre com seus dados de acesso.', 'error');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getAuthToken = async () => {
    const authkey = searchParams.get("authkey");
    if(authkey)
      notify('Estamos validando os seu token de acesso. Aguarde um momento', 'success');
      await loginWithToken(authkey);
  };

  useEffect(() => {
    console.log('1');
    getAuthToken();
  }, [])
  

  return (
    <div className="mt-8 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <img src="../logo-communica.png" alt="CommunicaZap" style={{width: "200px"}}/>
        {/* <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4> */}
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          name="email"
          type="text"
          onchange={handleChange}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          name="password"
          type="password"
          onchange={handleChange}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <button onClick={login} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href="https://www.communicazap.com/"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
}

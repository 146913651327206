import React, { useState } from 'react';
import Modal from 'react-modal';
import ApiCustomer from 'services/ApiCustomer';
import { notify } from "utils/notify";


Modal.setAppElement('#root'); // Define o elemento raiz do seu aplicativo

function CustomerModal({ isOpen, onRequestClose, onActionSuccess   }) {
  const [customerData, setCustomerData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await ApiCustomer.create(customerData);
      console.log('response ApiCustomer --> ', response);
      if (response.data) {
        console.log('response ApiCustomer --> ', response);
        notify('Cadastrado com Sucesso!', 'success');
        setTimeout(() => {
          onActionSuccess();
          onRequestClose();
        }, 300);
      }
    } catch (error) {
      console.log('Erro ao cadastrar cliente: ', error);
      if (error.response.data.errors) {
        console.error('Erro na API: ', error.response.message);
        const errorMessage = error.response.data.message || 'Erro desconhecido';
        notify(errorMessage, 'error');
      } else {
        console.error('Erro de rede: ', error.message);
        notify('Erro de rede', 'error');
      }
    }
  }

  return (
    <div>
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Cadastrar Cliente"
      className={`modal fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white rounded-lg p-6 shadow-lg ${isOpen ? "modal-open" : ""}`}
      overlayClassName={`modal-overlay ${isOpen ? "modal-open" : ""}`}
    >
    <h2 className="text-2xl font-bold mb-4">Cadastrar Cliente</h2>
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700">Nome:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={customerData.name}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700">Email:</label>
        <input
          type="text"
          id="email"
          name="email"
          value={customerData.email}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="phone" className="block text-gray-700">Celular:</label>
        <input
          type="text"
          id="phone"
          name="phone"
          value={customerData.phone}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="segmento" className="block text-gray-700">Segmento:</label>
        <input
          type="text"
          id="segmento"
          name="segmento"
          value={customerData.segmento}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={onRequestClose}
          className="mr-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
        >
          Fechar
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
        >
          Salvar
        </button>
      </div>
    </form>
    
  </Modal>
  </div>
  
  );
}

export default CustomerModal;

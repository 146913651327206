import api from './api';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  login: async (params) => { 
      return api.post('/login', params);
  },

  loginWithToken: async (params) => { 
    return api.post('/login-token', params);
},

  me: (token) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return api.get('/user');
  }

};
